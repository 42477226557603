import React from 'react'
import Container from 'components/container/Container'
import ContentLinks from 'features/energy-gardens/landingpage/components/navigation/content-links/ContentLinks'
import { MAIN_CONTAINER_ID } from 'features/energy-gardens/landingpage/constants'
import SolarPanelSelection from 'features/energy-gardens/landingpage/components/solar-panel-selection/SolarPanelSelection'
import useWindowSize from 'hooks/useWindowSize'
import ArticleSection from 'features/energy-gardens/landingpage/components/article-section/ArticleSection'
import Img from 'components/img/Img'
import { useGetEnergyGardenBySlugQuery } from 'store/api/boltApi'
import { useRouter } from 'next/router'
import { CONTENT_IDS } from 'features/energy-gardens/landingpage/routing/types'
import { PDF_FILES } from 'features/energy-gardens/constants'
import QuestionsInfoMomentCard from 'features/energy-gardens/landingpage/views/about-the-project/QuestionsInfoMomentCard/QuestionsInfoMomentCard'

const AboutTheProject = () => {
  // Window size
  const { isDesktop } = useWindowSize()

  // Router
  const { query } = useRouter()
  const { slug } = query

  // RTQuery
  const { data } = useGetEnergyGardenBySlugQuery(slug as string)

  // Constants
  const { products = [] } = data || {}
  const solarPanels = products.find((product) => product.name === 'solar panels')
  const { totalQuantity = 0 } = solarPanels || {}

  return (
    <Container>
      {isDesktop && (
        <Container.LeftAside>
          <ContentLinks isSticky />
        </Container.LeftAside>
      )}
      <Container.Main id={MAIN_CONTAINER_ID}>
        <QuestionsInfoMomentCard />

        {/*Intro*/}
        <ArticleSection id={CONTENT_IDS.INTRO} isIntro>
          <p>
            De Brugse zonnetuin biedt jou de kans om te investeren in zonnepanelen op het dak van tegelbedrijf Tyles en 25 jaar lang te
            genieten van het rendement van de duurzame stroom die ze opwekken.
          </p>
        </ArticleSection>

        {/*Wat*/}
        <ArticleSection id={CONTENT_IDS.WHAT}>
          <h2>Wat</h2>
          <p>
            De Brugse zonnetuin is een een initiatief van diverse Brugse buurten, vzw Brugge Geeft Energie en energieplatform Bolt, en wordt
            ondersteund door Stad Brugge. Het plan is om {totalQuantity} zonnepanelen te installeren op het dak van het Brugse bedrijf
            Tyles. De aankoop van de zonnepanelen wordt mogelijk door een investering in groep, wat van de Brugse zonnetuin een collectief
            energieproject maakt. De deelnemers dragen samen bij aan de verduurzaming van hun stad en genieten elk van de voordelen van
            zonne-energie via Bolt. De financiering van de Brugse zonnetuin gebeurt in 4 fases, waarbij na elke opgehaalde fase de
            zonnepanelen van dat deel geïnstalleerd zullen worden.
          </p>
          <blockquote>
            <p>
              “Zonne-energie is de toekomst en daar moeten we in Brugge samen werk van maken. Met het klimaatplan BruggeNaarMorgen
              ondersteunen we graag initiatieven zoals deze Brugse zonnetuin.”
            </p>
            <footer>
              <Img publicId="website/energy-gardens/avatar-dirk-de-fauw" fill />
              <p>
                <strong>Dirk De fauw</strong>
                <br />
                Burgemeester Brugge
              </p>
            </footer>
          </blockquote>
        </ArticleSection>

        {/* Voor wie */}
        <ArticleSection id={CONTENT_IDS.FOR_WHOM}>
          <h2>Voor wie</h2>
          <p>
            Dit project is bedoeld voor inwoners van Brugge en omstreken die zelf geen zonnepanelen kunnen of willen leggen, maar toch
            willen bijdragen aan een groenere buurt en genieten van de voordelen van zonne-energie opwekken.
          </p>
        </ArticleSection>

        {/* Waarom */}
        <ArticleSection id={CONTENT_IDS.WHY}>
          <h2>Waarom</h2>
          <p>
            Bolt en de Brugse buurtcomités zijn ervan overtuigd dat we het klimaatprobleem enkel kunnen oplossen als elke Belg zélf lokale,
            duurzame energie begint op te wekken. Met dit project geven we meer mensen de kans om dat te doen. We willen met de Brugse
            zonnetuin ook de lokale economie stimuleren en de buurt meer zelfvoorzienend en toekomstbestendig maken. Samen bouwen we aan een
            gezonde stad en zetten we de energiemarkt op zijn kop.
          </p>
        </ArticleSection>

        {/* Meedoen */}
        <ArticleSection id={CONTENT_IDS.PARTICIPATE}>
          <h2>Meedoen</h2>
          <p>
            Jij kan deelnemen aan dit project door te investeren in 4, 8, 12 of 16 zonnepanelen voor een periode van 25 jaar. Door dit te
            doen wek je binnenkort duurzame stroom op die je eenvoudigweg verkoopt aan anderen via het Bolt-platform. De Brugse zonnetuin
            wordt georganiseerd in de vorm van een lening. Details vind je in{' '}
            <a href={PDF_FILES.LOAN_TERMS} rel="noreferrer noopener" target="_blank">
              de leningsvoorwaarden
            </a>{' '}
            en{' '}
            <a href={PDF_FILES.INFORMATION_NOTE} rel="noreferrer noopener" target="_blank">
              informatienota
            </a>
            ,{' '}
            <a href={PDF_FILES.INFORMATION_NOTE_ADDENDUM_ONE} rel="noreferrer noopener" target="_blank">
              addendum
            </a>{' '}
            ,{' '}
            <a href={PDF_FILES.INFORMATION_NOTE_ADDENDUM_TWO} rel="noreferrer noopener" target="_blank">
              addendum 2
            </a>{' '}
            en{' '}
            <a href={PDF_FILES.INFORMATION_NOTE_ADDENDUM_THREE} rel="noreferrer noopener" target="_blank">
              addendum 3
            </a>
            . Addendum 3 beschrijft de opsplitsing van de investering in 4 fases.
          </p>
        </ArticleSection>

        {/* Jouw voordelen */}
        <ArticleSection id={CONTENT_IDS.ADVANTAGES} emphasize>
          <h2>Jouw voordelen</h2>
          <ul>
            <li>Je draagt rechtstreeks bij aan de verduurzaming van je stad en het Belgische stroomnetwerk.</li>
            <li>Je zal voor een periode van 25 jaar vergoed worden voor de hoeveelheid stroom die de zonnepanelen opwekken.</li>
            <li>Je dekt jezelf in tegen prijsstijgingen: hoe hoger de marktprijzen, hoe hoger jouw vergoeding.</li>
            <li>Je hoeft zelf niets te doen: wij staan in voor het onderhoud, de reparatie en verzekering van je panelen.</li>
            <li>Dankzij je deelname aan een collectieve energietuin, krijg je jaarlijks €75 korting voor je aansluiting bij Bolt.</li>
          </ul>
        </ArticleSection>

        {/* News headers */}
        <ArticleSection>
          <Img publicId="website/energy-gardens/news-headers-updated" fill />
        </ArticleSection>

        {/*Locatie*/}
        <ArticleSection id={CONTENT_IDS.LOCATION}>
          <h2>Over de locatie</h2>
          <p>
            Tyles is een Brugs tegelbedrijf dat zich sinds 1909 specialiseert in de verkoop van kwalitatieve, keramische tegels. Ze zetten
            mee hun schouders onder dit project door hun dak ter beschikking te stellen van de Brugse zonnetuin.
          </p>
          <blockquote>
            <p>
              “Er zijn veel Bruggelingen die geen zonnepanelen kunnen plaatsen: door de bouwrestricties van Stad Brugge, doordat ze in een
              appartement wonen of gewoonweg omdat de financiële middelen ontbreken. Dat terwijl wij voldoende plek hebben op het dak van
              ons magazijn. Het idee voor een collectieve zonnetuin leek ons plots een evidentie.”
            </p>
            <footer>
              <Img publicId="website/energy-gardens/avatar-guy-royaux" fill />
              <p>
                <strong>Guy Royaux</strong>
                <br />
                CEO, Tyles
              </p>
            </footer>
          </blockquote>
          <p>
            Per gefinancierde fase wordt dat aantal zonnepanelen geplaatst in de Brugse zonnetuin op het dak van het magazijn van Tyles. Dat
            ligt op enkele kilometers van het centrum van Brugge. De familie achter het bedrijf Tyles hoopt alvast dat andere bedrijven hun
            voorbeeld zullen volgen.
          </p>
          <Img publicId="website/energy-gardens/tyles-energy-garden-mock" fill />
        </ArticleSection>

        {/* Bolt */}
        <ArticleSection id={CONTENT_IDS.ABOUT_BOLT}>
          <h2>Over Bolt</h2>
          <p>
            Bolt is het allereerste energieplatform in België dat het mogelijk en supergemakkelijk maakt om hernieuwbare stroom uit te
            wisselen tussen energie-opwekkers en -afnemers. Bolt biedt daarmee een antwoord op de klimaatproblematiek en een misleidende
            energiemarkt. Met collectieve energietuinen wil Bolt bovendien elke Belg de kans geven om duurzame energie op te wekken.
          </p>
        </ArticleSection>

        <QuestionsInfoMomentCard />
      </Container.Main>
      <Container.RightAside>
        <SolarPanelSelection />
      </Container.RightAside>
    </Container>
  )
}

export default AboutTheProject
