import { routes } from 'utils/routes'
import Card from 'components/card/Card'
import { useRouter } from 'next/router'
import { Heading } from '@boltenergy-be/design-system'
import styles from './QuestionsInfoMomentCard.module.scss'
import dayjs from 'dayjs'
import { getNextInfoSession } from 'features/energy-gardens/landingpage/views/info-session/utils'
import { InfoSessionType } from 'features/energy-gardens/landingpage/views/info-session/types'
import Link from 'components/link/Link'

const QuestionsInfoMomentCard = () => {
  // Router
  const router = useRouter()
  const { locale, query } = router
  const slug = query?.slug

  // Constants
  const { next: nextOnlineInfoSession } = getNextInfoSession()

  return nextOnlineInfoSession ? (
    <Card compact as="section" className={styles['questions-card']}>
      <header>
        <Heading as="h2" variant="h5">
          Heb je een vraag?
        </Heading>
      </header>

      <p>
        Bel ons op{' '}
        <a className={styles.phone} href="tel:+32470188684">
          <strong>+32 470 18 86 84</strong>
        </a>{' '}
        {nextOnlineInfoSession.type === InfoSessionType.ONLINE ? (
          <>
            of neem deel aan het <strong>online infomoment</strong> op {dayjs(nextOnlineInfoSession.date).format('DD MMMM')} van{' '}
            {nextOnlineInfoSession.from} tot {nextOnlineInfoSession.to}
          </>
        ) : (
          <>
            of neem deel aan het infomoment op {dayjs(nextOnlineInfoSession.date).format('DD MMMM')} in{' '}
            {nextOnlineInfoSession.location.city} van {nextOnlineInfoSession.from} tot {nextOnlineInfoSession.to}
          </>
        )}
      </p>

      <footer className={styles.footer}>
        <Link representation="button" variant="secondary-on-accent" href={nextOnlineInfoSession.url} target="_blank">
          Schrijf je in voor het infomoment
        </Link>

        <Link representation="button" variant="primary-on-accent" href={routes(locale, slug as string).energyGardenContact}>
          Of neem contact op
        </Link>
      </footer>
    </Card>
  ) : null
}

export default QuestionsInfoMomentCard
