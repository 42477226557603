import { INFO_SESSIONS } from 'features/energy-gardens/landingpage/constants'
import dayjs from 'dayjs'
import { InfoSessionType } from 'features/energy-gardens/landingpage/views/info-session/types'

export const getNextInfoSession = () => {
  const sortedSessions = INFO_SESSIONS.sort((a, b) => (dayjs(b.date).isBefore(dayjs(a.date)) ? 1 : -1))
  return {
    [InfoSessionType.ONLINE]: sortedSessions.find(
      (session) => dayjs().isBefore(dayjs(session.date)) && session.type === InfoSessionType.ONLINE
    ),
    [InfoSessionType.PHYSICAL]: sortedSessions.find(
      (session) => dayjs().isBefore(dayjs(session.date)) && session.type === InfoSessionType.PHYSICAL
    ),
    next: sortedSessions.find((session) => dayjs().isBefore(dayjs(session.date)))
  }
}
